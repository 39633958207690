// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjmUwMYU0dM5RTi0bhnbEpz-xZ2sOH8Pg",
  authDomain: "splatoon-50cda.firebaseapp.com",
  databaseURL: "https://splatoon-50cda-default-rtdb.firebaseio.com",
  projectId: "splatoon-50cda",
  storageBucket: "splatoon-50cda.appspot.com",
  messagingSenderId: "396100472351",
  appId: "1:396100472351:web:311b674ff09afd300b8f2a",
  measurementId: "G-WCYBL3X97Q",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
