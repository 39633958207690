import { useState } from "react";
import megaLoge from "../assets/mega.png";
import powerball from "../assets/powerball.png";
import "./Lottery.css";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Lottery() {
  const [powerballToWin, setPowerballToWin] = useState([]);
  const [megaToWin, setMegaToWin] = useState([]);
  const [powerballHis, setPowerballHis] = useState([]);
  const [megaHis, setMegaHis] = useState([]);

  const numberGenerator = (arrayLength, max) => {
    const randomArray = Array.from({ length: arrayLength }, () =>
      Math.floor(1 + Math.random() * max)
    );
    const newRandomArray = [];

    randomArray.forEach((number) => {
      let newRandom = 0;
      for (let i = 0; i < number; i++) {
        newRandom = Math.floor(1 + Math.random() * max);
      }
      while (newRandomArray.includes(newRandom)) {
        newRandom = Math.floor(1 + Math.random() * max);
      }
      newRandomArray.push(newRandom);
    });
    return newRandomArray.sort((a, b) => a - b);
  };

  const megaPicker = () => {
    return [...numberGenerator(5, 70), ...numberGenerator(1, 25)];
  };
  const powerballPicker = () => {
    return [...numberGenerator(5, 69), ...numberGenerator(1, 26)];
  };

  return (
    <div className="mainPageContainer">
      <Link to="/splatoon3fest" style={{ justifySelf: "end" }}>
        <Button variant="contained">Splatoon Fest Vote</Button>
      </Link>
      <div className="pickerContainer">
        <img
          src={powerball}
          alt="illustration"
          style={{ width: "100%", paddingRight: "1rem" }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setPowerballHis([powerballToWin, ...powerballHis]);
            setPowerballToWin(powerballPicker());
          }}
        >
          Pick Now!
        </Button>
        {powerballToWin.map((number, idx) =>
          idx !== 5 ? (
            <div className="renderNumber">{number}</div>
          ) : (
            <div className="renderSpecialNumber">{number}</div>
          )
        )}
      </div>
      <div className="pickerContainer">
        <img
          src={megaLoge}
          alt="illustration"
          style={{ width: "100%", paddingRight: "1rem" }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setMegaHis([megaToWin, ...megaHis]);
            setMegaToWin(megaPicker());
          }}
        >
          Pick Now!
        </Button>
        {megaToWin.map((number, idx) =>
          idx !== 5 ? (
            <div className="renderNumber">{number}</div>
          ) : (
            <div className="renderSpecialNumber">{number}</div>
          )
        )}
      </div>

      <Paper elevation={10}>
        <Grid container sx={12}>
          <Grid item xs={6}>
            <Typography variant="h4" className="sectionTitle">
              Powerball Sets
            </Typography>
            <div className="HisContainer">
              {powerballHis.map((his) =>
                his.map((number, idx) =>
                  idx !== 5 ? (
                    <div className="renderNumber">{number}</div>
                  ) : (
                    <div className="renderSpecialNumber">{number}</div>
                  )
                )
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" className="sectionTitle">
              Mega Sets
            </Typography>
            <div className="HisContainer">
              {megaHis.map((his) =>
                his.map((number, idx) =>
                  idx !== 5 ? (
                    <div className="renderNumber">{number}</div>
                  ) : (
                    <div className="renderSpecialNumber">{number}</div>
                  )
                )
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
