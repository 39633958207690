import zombie from "../assets/zombie.jpg";
import ghost from "../assets/ghost.jpg";
import skeleton from "../assets/skeleton.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { app } from "../initFirebase";
import { getDatabase, ref, child, get, set } from "firebase/database";
import "./SplatoonFest.css";
import { useEffect, useState } from "react";
import { Histogram } from "../Histogram";

const initTableData = {
  zombie: 0,
  skeleton: 0,
  ghost: 0,
};

export default function SplatoonFest() {
  const splatoonDB = getDatabase(app);
  const [tableData, setTableData] = useState(initTableData);
  const [formValue, setFormValue] = useState("");
  const [isVoted, setIsVoted] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarShowAlert, setIsSnackbarShowAlert] = useState(false);
  useEffect(() => {
    setIsVoted(localStorage.getItem("isVoted"));
    getVoteData();
  }, []);

  const getVoteData = () => {
    get(child(ref(getDatabase()), "splatoon/fest/halloween"))
      .then((db) => {
        if (db.exists()) {
          console.log(db.val());
          setTableData(db.val());
        } else {
          console.log("No data available.");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOnClick = () => {
    if (formValue !== "") {
      set(ref(splatoonDB, "splatoon/fest/halloween"), {
        ...tableData,
        [formValue]: tableData[formValue] + 1,
      });
      setIsVoted(true);
      localStorage.setItem("isVoted", "true");
      getVoteData();
      setIsSnackbarOpen(true);
    } else {
      setIsSnackbarShowAlert(true);
      setIsSnackbarOpen(true);
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const handleFormChange = (e) => {
    e.target.value === "zombie"
      ? setFormValue("zombie")
      : e.target.value === "skeleton"
      ? setFormValue("skeleton")
      : setFormValue("ghost");
    isSnackbarOpen && setIsSnackbarOpen(false);
    setIsSnackbarShowAlert(false);
  };
  return (
    <Stack style={{ width: "100%" }}>
      <FormControl disabled={isVoted} style={{ paddingBottom: "5%" }}>
        <FormLabel id="halloween" className="title">
          Splatoon3 Halloween Fest
        </FormLabel>
        <RadioGroup
          name="halloweenFestGroup"
          style={{ padding: "3%" }}
          onChange={handleFormChange}
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Stack>
                <img src={zombie} alt="zombie" />
                <FormControlLabel
                  className="team"
                  value="zombie"
                  control={<Radio />}
                  label="Zombie - 莎莎队"
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <img src={skeleton} alt="skeleton" />
                <FormControlLabel
                  className="team"
                  value="skeleton"
                  control={<Radio />}
                  label="Skeleton - 黑人队"
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <img src={ghost} alt="ghost" />
                <FormControlLabel
                  className="team"
                  value="ghost"
                  control={<Radio />}
                  label="Ghost - 煎鱼饼队"
                />
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>
        <Button
          variant="contained"
          onClick={() => handleOnClick()}
          style={{ width: "10rem", alignSelf: "center" }}
          disabled={isVoted}
        >
          Vote!
        </Button>
      </FormControl>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={isSnackbarShowAlert ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnackbarShowAlert ? "Please join a team!" : "Voted!"}
        </Alert>
      </Snackbar>
      <Accordion
        style={{
          width: "50%",
          alignSelf: "center",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>See Record</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={16} style={{ backgroundColor: "#fbbc04" }}>
            <Histogram
              width={700}
              height={400 - 40}
              data={Object.values(tableData)}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
