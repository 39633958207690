import splMatchCover from "../assets/splMatchCover.jpg";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { app } from "../initFirebase";
import { getDatabase, ref, child, get, set } from "firebase/database";
import "./SplatoonFest.css";
import { useEffect, useState } from "react";

// const maps = {
//   1: "游戏里面就有随机地图",
//   2: "程序员正在摸鱼",
//   3: "程序员正在打喷",
//   4: "懒得写",
// };
const model = {
  1: "区域，爆炸桶给我下来",
  2: "鱼虎，4K千里停鱼",
  3: "塔楼，长热走起",
  4: "蛤蜊，算你们倒霉",
};

const matchs = {
  match1: [],
  match2: [],
  match3: [],
  filled: false,
};

export default function SplatoonMatch() {
  const splatoonDB = getDatabase(app);
  const [matchData, setMatchData] = useState(matchs);
  const [matchId, setMatchId] = useState("");
  const [isMatchinvalid, setIsMatchinvalid] = useState(true);

  const getMatchData = (match) => {
    get(child(ref(getDatabase()), "splatoon/match"))
      .then((db) => {
        if (!!db.val()[match]) {
          setMatchData({
            ...matchData,
            ...db.val()[match],
          });
          setIsMatchinvalid(false);
        } else {
          setMatchData(matchs);
          setIsMatchinvalid(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const getOneNewMatchNum = () => {
    // const selectModel = Math.floor(1 + Math.random() * 4);
    // const selectMap = Math.floor(1 + Math.random() * 4);
    // return [model[selectModel], maps[selectMap]];
    return Math.floor(1 + Math.random() * 4);
  };

  const getFourMatches = () => {
    const matchArr = [];
    for (let idx = 0; idx < 4; idx) {
      let temp = getOneNewMatchNum();
      if (!matchArr.includes(temp)) {
        matchArr.push(temp);
        idx++;
      }
    }

    return matchArr.map((item) => model[item]);
  };

  const HandleMatchCreate = () => {
    if (matchId !== "" && !matchData.filled) {
      const matchSet = getFourMatches();
      matchId === "021"
        ? set(ref(splatoonDB, `splatoon/match/${matchId}`), {
            match1: matchSet[0],
            match2: matchSet[1],
            match3: matchSet[2],
            match4: matchSet[3],
            match5: model[getOneNewMatchNum()],
            filled: true,
          })
        : set(ref(splatoonDB, `splatoon/match/${matchId}`), {
            match1: matchSet[0],
            match2: matchSet[1],
            match3: matchSet[2],
            filled: true,
          });
    }
    getMatchData(matchId);
  };

  useEffect(() => {
    setMatchData({
      ...matchData,
      filled: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);
  return (
    <>
      <img alt="Header" src={splMatchCover} style={{ width: "100%" }} />

      <FormControl>
        <Grid container padding={3}>
          <Grid item>
            <TextField
              id="match"
              label="Round Number"
              required
              onChange={(e) => {
                setMatchId(e.target.value.toString());
              }}
            />
          </Grid>
          <Grid item padding={1}>
            <Button
              variant="contained"
              type="submit"
              onClick={() => getMatchData(matchId)}
              disabled={matchId === ""}
            >
              Check!
            </Button>
          </Grid>
        </Grid>
      </FormControl>

      <Grid xs={12}>
        <Button
          variant="contained"
          disabled={matchId === "" || matchData.filled}
          onClick={() => HandleMatchCreate(matchId)}
        >
          康康真格模式
        </Button>
      </Grid>
      {!isMatchinvalid && (
        <Grid container style={{ flexDirection: "column" }} padding={4}>
          <Grid item>
            <Paper elevation={8}>
              <div>程序员偷懒了，无图</div>
              <Typography>Match 1 : {matchData.match1}</Typography>
              <Typography>Match 2 : {matchData.match2}</Typography>
              <Typography>Match 3 : {matchData.match3}</Typography>
              {matchId === "021" &&
                !!matchData.match4 &&
                !!matchData.match5 && (
                  <>
                    <Typography>Match 4 : {matchData.match4}</Typography>
                    <Typography>Match 5 : {matchData.match5}</Typography>
                  </>
                )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
