import { createHashRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import "./components/Lottery";
import SplatoonFest from "./components/SplatoonFest";
import SplatoonMatch from "./components/SplatoonMatch";
import Lottery from "./components/Lottery";
import ErrorPage from "./ErrorPage";

const router = createHashRouter([
  {
    path: "/",
    element: <SplatoonMatch />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/lottery",
    element: <Lottery />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/splatoon3fest",
    element: <SplatoonFest />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
